import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Button, Title, Text, Box } from "../components/Core";
import PageWrapper from "../components/PageWrapper";
import Contact from "../sections/common/Contact";
import { device } from "../utils";

import ReactPlayer from "react-player"
import "../assets/fonts/icon-font/css/style.css";
import ImageSwiperWatchItSoftware from "../components/ImageSwiperWatchItSoftware";
import { height } from "styled-system";

const WorkSingle = () => {
  return (
    <>
      <PageWrapper darkTheme>
        <Section className="mt-lg-5">
          <Container>
            <Row>
              <Col lg="8">
                <Text variant="tag">user design</Text>
                <Title variant="secSm" className="my-4">
                  Watch It{" "}
                </Title>
                <Text
                  variant="p"
                  css={`
                    max-width: 750px;
                  `}
                >
                  Using design methods to support problem solving and decision making, we built a proof-of-concept prototype of a product
                  to assist first responders in situations of physical or emotional distress. To support our work we used various research
                  methods, visual and interaction design skills, methodologies for evaluating designs, and techniques for communicating our
                  designs.
                </Text>
              </Col>
            </Row>
          </Container>
        </Section>
        <div className="mt-lg-3">
          <Container>
            <Row>
              <Col lg="4" className="mb-4 mb-lg-0">
                <Text variant="tag">Role</Text>
                <Title variant="cardBig" className="mt-3">
                  UX Researcher &<br/> Product Designer
                </Title>
              </Col>
              <Col lg="4" className="mb-4 mb-lg-0">
                <Text variant="tag">Date</Text>
                <Title variant="cardBig" className="mt-3">
                  Sep 2018 - Dec 2018
                </Title>
              </Col>
              <Col lg="4">
                <Link
                  a href="../../Design_Specifications_Document.pdf" target="_blank"
                  spy={true}
                  smooth={true}
                  offset={-50}
                  duration={1000}
                >
                  <Button arrowRight>Design Specifications</Button>
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
        <Section className="mt-lg-5">
          <Container>
            <Row style={{marginTop: -20}}>
              <Col xs="8" class="mx-auto" className="mb-2">
                  <img src={"https://res.cloudinary.com/ryandaaang/image/upload/v1619969508/Watch%20It/Watch_It.png"} alt="watch it logo" className="img-fluid w-100" />
              </Col>
            </Row>
          </Container>
        </Section>
        <Section className="pt-4 pb-0">
          <Container>
            <Title variant="secSm" className="mb-5 pb-lg-4" style={{marginTop: -60}}>
              Project Process
            </Title>
                <Title variant="cardBig" className="mb-4" style={{marginTop: -30}}>
                  01. Challenge
                </Title>
                <Text variant="p" className="mb-4">
                  Working in a team of 3 designers, we were given the challenge of designing for disaster. Our group was
                  given the problem space of designing for <span style={{fontWeight: "bold"}}>first responders</span>. We
                  needed to iterate on ideas for intervening in that design space that will address a specific problem.
                </Text>
                <Text variant="p" className="mb-4">
                  During the middle of our design process, we were hit with a curveball in the form of a design constraint.
                  Out of the multiple different types of design constraints, our team was randomly given the constraint of 
                  <span style={{fontWeight: "bold"}}> having first responders down</span>. This presented another challenge
                  that we had to overcome.
                </Text>
                <Title variant="cardBig" className="mb-4" style={{marginTop: 50}}>
                  02. Design Space
                </Title>
                <Text variant="p" className="mb-4">
                  First responders are monumental in the protection and recovery of communities when disasters strike. Some
                  elements of the system revolving around first responders are their funding, training, facility, department,
                  team, diet, mental health, family, and themselves. These parts are interconnected through disasters such as
                  fire, crime, or car accidents. Sadly, this flow is powered by all the dangers in our society that pose a
                  threat to innocent lives.
                </Text>
                <Title variant="cardBig" className="mb-4" style={{marginTop: 50}}>
                  03. Contextual Inquiry Interviews
                </Title>
                <Text variant="p" className="mb-4" style={{marginBottom: 30}}>
                  We conducted contextual inquiry interviews with individuals within our design space of first responders. We observed and 
                  interviewed a police officer and a lifeguard as they performed an activity or task related to our problem space of
                  protecting the community.
                </Text>

                <Title variant="card" className="mb-3">
                  Officer Dahmar Wartt-Smiles
                </Title>
                <Text variant="p" className="mb-4" style={{marginBottom: 30}}>
                  Findings:
                  <ul className="list" style={{marginLeft: 40, marginBottom: 10, marginTop: 10}}>
                    <li>Work-life imbalance</li>
                    <li>Stress due to nature of work</li>
                    <li>No coping mechanisms in place = vastly negative effects</li>
                    <li>Counseling resources are readily available but they must reach out</li>
                  </ul>
                </Text>
                <Title variant="card" className="mb-3">
                  Lifeguard Dylan Dang
                </Title>
                <Text variant="p" className="mb-4" style={{marginBottom: 30}}>
                  Findings:
                  <ul className="list" style={{marginLeft: 40, marginBottom: 10, marginTop: 10}}>
                    <li>Anxiety from the responsibilities put on their shoulders</li>
                    <li>Exhaustion from constantly being alert</li>
                    <li>Stress from tragic situations</li>
                    <li>Counseling resources are not readily available</li>
                  </ul>
                </Text>
                <Text variant="p" className="mb-4" style={{marginBottom: 30}}>
                  Through our contextual inquiries, we were able to immerse ourselves in the work environments of our targeted
                  users of first responders. We used what we learned about their difficulties to identify ways in which we
                  can intervene in that space with a product idea.
                </Text>
                <Text variant="p" className="mb-4" style={{marginBottom: 30, fontStyle: "italic"}}>
                  A detailed report of our contextual inquiry interviews can be found within our
                  <a href="../../Design_Specifications_Document.pdf" target="_blank"> design specifications document</a>.
                </Text>
                <Title variant="cardBig" className="mb-4" style={{marginTop: 50}}>
                  04. Identifying Problem Space Through User Research
                </Title>
                <Text variant="p" className="mb-4">
                  Through our user research, we have discovered numerous studies that highlight the importance of managing mental health
                  as first responders. Here are our main takeaways:
                  <ul className="list" style={{marginLeft: 40, marginBottom: 10, marginTop: 10}}>
                    <li>
                      Higher risk of being exposed to constant trauma due to near death experiences, proximity to distressed 
                      civilians, or casualties.
                    </li>
                    <li>
                      Strain on their personal relationships since their line of work is dangerous and demanding.
                    </li>
                    <li>
                      If the mental or physical health of a first responder is poor, they are putting those they are helping at risk by 
                      making poor decisions.
                    </li>
                  </ul>
                </Text>
                <Text variant="p" className="mb-4">
                  Below is a system diagram of the relationships between what a first responder does and how the events that can occur while they are
                  on the job can affect their well-being:
                </Text>
                <Row>
                  <Col xs="12" className="mb-5">
                    <img src={"https://res.cloudinary.com/ryandaaang/image/upload/v1626907628/Watch%20It/System_Diagram.png"} alt="system diagram of disaster" className="img-fluid w-100"/>
                  </Col>
                </Row>
                <Title variant="cardBig" className="mb-4" style={{marginTop: 50}}>
                  05. Design Intervention Ideation
                </Title>
                <Text variant="p" className="mb-4">
                  From the system diagram above, we looked at situations where we could intervene with a product designed to address the specific problem of
                  first responders' well-being. Areas highlighted in green shows the events where our product will intervene on the first responder system:
                </Text>
                <Row>
                  <Col xs="12" className="mb-5">
                    <img src={"https://res.cloudinary.com/ryandaaang/image/upload/v1626908378/Watch%20It/System_Diagram_-_Intervention.png"} alt="system diagram of disaster interventions" className="img-fluid w-100"/>
                  </Col>
                </Row>
                <Text variant="p" className="mb-4">
                  We brainstormed two biometric device ideas and wanted to pair it with software to display the collected data:
                  <ul className="list" style={{marginLeft: 40, marginBottom: 10, marginTop: 10}}>
                    <li style={{fontWeight: "bold"}}>Smartwatch</li>
                      <ul className="list" style={{marginLeft: 40, marginBottom: 10, marginTop: 10}}>
                        <li>Pros: Compact and ability to measure different vital signs.</li>
                        <li>Cons: Incapable of measuring respiratory rate as of today.</li>
                      </ul>
                    <li style={{fontWeight: "bold"}}>Vest</li>
                      <ul className="list" style={{marginLeft: 40, marginBottom: 10, marginTop: 10}}>
                        <li>Pros: Ability to measure different vital signs.</li>
                        <li>Cons: Bulky and incapable of measuring blood pressure as of today.</li>
                      </ul>
                    <li><span style={{fontWeight: "bold"}}>Software</span>: Regardless of the prototype, we will need an interface to display 
                      the collected data from the devices. The interface allows first responders to view a history of their vital signs
                      and communicate with doctors, colleagues, and family members. There will be a mood tracker feature that allows
                      for an opportunity to write about their daily experiences.
                    </li>
                  </ul>
                  </Text>
                  <Text variant="p" className="mb-4">
                    Since we were not experts in biometric devices and sensors, we decided to reach out to a subject-matter expert. We got in
                    touch with 
                    <a href="http://matthewwhitehill.com/" target="_blank"> Matt Whitehill </a>who works at the
                    <a href="https://ubicomplab.cs.washington.edu" target="_blank"> University of Washington's Ubiquitous Computing research lab</a>. 
                    The lab develops sensing systems for real world applications in health, sustainability, and novel interactions. He helped us
                    narrow down our decision with his expertise and we decided to go with the smartwatch idea due to its compactness and ability
                    to measure 3 out of 4 vital signs.
                  </Text>
                  {/* We ideated on different 
                  From the previous systems diagram, we identified areas where we could intervene 
                  A biometric monitoring device designed specifically for first responders is needed because their well-being affects
                  multiple stakeholders with high stakes. A small, lightweight device would be versatile for all types of first responders.
                  The device would constantly monitor bodily function so that someone will be notified when a first responder should not be
                  on site. We brainstormed two biometric device ideas which are a smartwatch and a vest. We wanted to pair it with 
                  software to display first responder data. Ultimately, we followed through with the idea of a smartwatch. */}
                <Title variant="cardBig" className="mb-4" style={{marginTop: 50}}>
                  06. Design Constraint
                </Title>
                <Text variant="p" className="mb-4">
                  As mentioned previously, we were given a random design constraint <span style={{fontWeight: "bold"}}> having first responders down</span>.
                  This caused us to panic at first because our problem space revolves around the first responders themselves. However, we came to the idea of
                  interpreting this constraint as having first responders down from physical or emotional distress and are in need of assistance. To
                  accommodate this new constraint, we added additional features:
                  <ul className="list" style={{marginLeft: 40, marginBottom: 10, marginTop: 10}}>
                    <li>
                      Ability for friends and family to keep track of their first responders' well-being.
                    </li>
                    <li>
                      Capability of notifying friends, family, or other first responders during situations where they are down physically.
                    </li>
                    <li>
                      Send a distress signal by holding down a button for an extended period of time.
                    </li>
                  </ul>
                </Text>
                {/* <iframe src="https://giphy.com/embed/26ufaJh2hfUtuSo6s" width="400" height="170" frameBorder="0" class="giphy-embed" allowFullScreen></iframe> */}
                <Title variant="cardBig" className="mb-4" style={{marginTop: 50}}>
                  07. Watch Prototype
                </Title>
                <Title variant="card" className="mb-3" style={{marginTop: 40}}>
                  Low-Fidelity Sketches
                </Title>
                <Row>
                  <Col xs="12" className="mb-5">
                    <img src={"https://res.cloudinary.com/ryandaaang/image/upload/v1626936560/Watch%20It/Watch_Sketch.png"} alt="low fidelity sketches of watch" className="img-fluid w-100"/>
                  </Col>
                </Row>
                <Title variant="card" className="mb-3" style={{marginTop: 10}}>
                  Proof Of Concept Prototype
                </Title>
                <Row>
                  <Col xs="12" className="mb-5">
                    <img src={"https://res.cloudinary.com/ryandaaang/image/upload/v1626936562/Watch%20It/Watch_Proof-Of-Concept.png"} alt="proof of concept prototype of watch" className="img-fluid w-100"/>
                  </Col>
                </Row>
                <Title variant="cardBig" className="mb-4" style={{marginTop: 30}}>
                  08. Software Prototype
                </Title>
                <Title variant="card" className="mb-3" style={{marginTop: 40}}>
                  Low-Fidelity Sketches
                </Title>
                <Col xs="13" className="mb-5">
                  <ImageSwiperWatchItSoftware></ImageSwiperWatchItSoftware>
                </Col>
                <Title variant="card" className="mb-3" style={{marginTop: 60}}>
                  High-Fidelity Prototype
                </Title>
                <div class="prototype">
                  <iframe class="responsive-iframe" src="https://marvelapp.com/55989eg?emb=1" width="1000" height="711" frameborder="0" allowtransparency="true"></iframe>
                </div>
                <Title variant="cardBig" className="mb-4" style={{marginTop: 50}}>
                  09. User Evaluation
                </Title>
                <Text variant="p" className="mb-4">
                  To evaluate our biometric device and software, we conducted interviews and surveys. We felt that these qualitative methods
                  would best help us in determining whether our product was feasible. We conducted a total of five interviews with different 
                  types of stakeholders and distributed our survey, asking questions about the biometric device design and user interface.
                  We included pictures of our prototypes in the Google Form survey and made sure to ask open-ended, non-leading questions.
                </Text>
                <Text variant="p" className="mb-4">
                  Findings:
                  <ul className="list" style={{marginLeft: 40, marginBottom: 10, marginTop: 10}}>
                    <li>The face of the watch was intuitive since respondents easily listed the vital signs the watch measures.</li>
                    <li>Respondents noted that the button on the side of the watch was ambiguous because there wasn’t a label on it.</li>
                    <li>Software User Interface: Participants understood that the medical data of the biometric device could help
                      stakeholders determine the health of a first responder.</li>
                    <li>95% of respondents said it was helpful and also expressed that they would consult doctors, family and friends if there 
                      was an abnormality shown in the data.</li>
                  </ul>
                </Text>
                <Title variant="cardBig" className="mb-4" style={{marginTop: 50}}>
                  10. Outcomes
                </Title>
                <Text variant="p" className="mb-4">
                  Completing this project strengthened my skills for conducting contextual inquiries, user evaluations, and prototyping.
                  Towards the end of our project, we learned that while our design may be a good idea, it is still an active area of research
                  and ahead of its time. Through user evaluation, we also realized that while nurses and first responders expressed interest
                  in the design, it is not designed for the everyday wearer.
                </Text>
                <Text variant="p" className="mb-4">
                  Even though we were faced with the design constraint of having first responders down within our problem space of first responders,
                  we were able to come up with solutions by interpreting the constraint in a different light. Our interpretation of having first
                  responders down from physical or emotional stress actually strengthened our design by narrowing down our problem space to create new 
                  targeted features.
                </Text>
                <Text variant="p" className="mb-4">
                  In a future iteration of this project, we would like to do more research to track first responder mood. We learned that
                  mental health is a difficult subject area to tackle and this project is only one tiny step in breaking down the stigma
                  first responders have around well-being.
                </Text>
                <Text variant="p" className="mb-4" style={{marginTop: 30}}>
                  Feel free to take a look at the video below which conveys our design process, challenges, and ultimate successes!
                </Text>
                <ReactPlayer url="https://www.youtube.com/watch?v=cNd-s7nWclU" controls="true"/>
          </Container>
        </Section>
        <Section mt="5%" bg="dark">
          <Container>
            <div className="text-center mt-lg-5">
              <Text variant="tag" className="mb-1" color="lightShade">
                Next Project
              </Text>
              <Link to="/seattle-crime-rate">
                <Button
                  arrowRight
                  className="border-0 bg-transparent shadow-none text-capitalize py-3"
                  css={`
                    font-weight: 700
                    font-size: 1.5rem;
                    letter-spacing: -1.2px;
                    line-height: 1.375;
                    @media ${device.md} {
                      font-size: 2rem;
                    }
                  `}
                >
                  Seattle Crime Rate
                </Button>
              </Link>
            </div>
          </Container>
        </Section>
        <Box py={4}>
          <Contact />
        </Box>
      </PageWrapper>
    </>
  );
};
export default WorkSingle;
