import React from "react";
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import CoreStyles from './core/styles.scss';
import AnimationStyles from 'react-awesome-slider/src/styled/scale-out-animation/scale-out-animation.scss';

const ImageSwiperWatchItSoftware = () => {
  return (
    <AwesomeSlider animation="scaleOutAnimation" cssModule={[CoreStyles, AnimationStyles]}>
      <div data-src="https://res.cloudinary.com/ryandaaang/image/upload/v1626935418/Watch%20It/1_-_Home.png"/>
      <div data-src="https://res.cloudinary.com/ryandaaang/image/upload/v1626935419/Watch%20It/2_-_Profile.png"/>
      <div data-src="https://res.cloudinary.com/ryandaaang/image/upload/v1626935419/Watch%20It/3_-_Messages.png"/>
      <div data-src="https://res.cloudinary.com/ryandaaang/image/upload/v1626935419/Watch%20It/4_-_Connections.png"/>
      <div data-src="https://res.cloudinary.com/ryandaaang/image/upload/v1626935418/Watch%20It/5_-_Mood_Tracker.png"/>
    </AwesomeSlider>
  );
};

export default ImageSwiperWatchItSoftware;